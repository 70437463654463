.imageSize{
    width: 100%;
    height: 20em;
    object-fit: fill;
    object-position: 100% 40%;
}
.gridItem{
    padding-top: 10px !important;
    padding-left: 5px !important;
}
@media only screen and (max-width: 300px) {
    .imageSize{
        height: 10em;
    }
} 
@media only screen and (max-width: 599px) {
    .imageSize{
        height: 45vw;
    }
}
@media only screen and (min-width: 600px) and (max-width: 999px) {
    .imageSize{
        height: 32vw;
    }
}
@media only screen and (min-width: 1000px) {
    .imageSize{
        height: 300px;
    }
}
